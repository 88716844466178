<template>
	<footer v-if="$route.name !== 'artists'" id="footer" class="footer">
		<div v-scrollanimation class="container">
			<div class="row">
				<div class="col-lg-12">
					<ul class="footer-navigation">
						<li v-for="(item, index) in general.main_navigation" :key="index">
							<a v-if="item.slug.includes('http')" :href="item.slug" target="_blank">
								{{ item.title }}
							</a>
							<router-link v-else :to="'/' + item.slug">
								{{item.title}}
							</router-link>
						</li>
					</ul>
					<div v-if="$store.state.country === 'au'" class="footer-acknowledgement">
						We acknowledge the traditional custodians of this land throughout Australia and recognise their continuing connection to 
						land, waters, communities and culture. We pay our respect to Elders past and present and to all Aboriginal and Torres Strait 
						Islanders. Warning: Aboriginal and Torres Strait Islander viewers are advised that this website may contain images, voices 
						and names of deceased persons.
					</div>
					<div class="footer-bottom">
						<ul class="footer-bottom-navigation">
							<li v-if="general.copyright_text" class="medium-hide">
								{{ general.copyright_text }}
							</li>
							<li v-for="(item, index) in general.secondary_navigation" :key="index">
                                <a v-if="item.slug.includes('privacy')" :href="url" target="_blank">
                                    {{ item.title }}
                                </a>
								<router-link v-else :to="'/' + item.slug">
									{{ item.title }}
								</router-link>
							</li>
							<li key="oneTrustPolicy">
								<a href="https://www.wminewmedia.com/cookies-policy/" target="_blank">Cookies Policy</a>
							</li>
							<li key="oneTrustSettings">
								<a class="ot-sdk-show-settings" style="cursor:pointer">Cookies Settings</a>
							</li>
							<li v-if="general.copyright_text" class="medium-show">
								{{ general.copyright_text }}
							</li>
						</ul>
						<ul class="footer-bottom-social">
							<li v-for="(item, index) in general.social_networks" :key="index">
								<a :href="item.url" target="_blank" :data-track="item.data_tracker + '-ftr'">
									<span :class="'icon-' + item.network.toLowerCase()"></span>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'FooterItem',
	computed: {
        ...mapState(['general']),
	},
    data() {
        return {
            url: 'https://www.wminewmedia.com/privacy/au/'
        }
    }
}
</script>
