<template >
	<div class="sticky-icons">
		<a v-for="(item, index) in general.social_networks"
			:key="index"
			:href="item.url"
			:data-track="item.data_tracker"
			target="_blank"
		
		>
			<span :class="'icon-' + item.network.toLowerCase()"></span>
		</a>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'SocialItem',
	computed: {
		...mapState(['general'])
	}
}
</script>
