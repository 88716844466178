<template>
	<header class="header" :class="{ 'active' : showHeader && $route.path !== '/artists' }">
		<router-link to="/" class="header-logo" :class="$store.state.country"></router-link>
		<span class="switcher mobile-hide" @click="toggleStyle()">
			<span class="switcher-icon"></span>
		</span>
		<div class="header-navigation">
			<ul v-if="checkObject(general)" class="header-navigation-list mobile-hide">
				<li v-for="(item, index) in general.header_navigation" :key="index">
					<a v-if="item.slug.includes('http')" :href="item.slug" target="_blank" :data-track="item.data_tracker">
						<span class="primary">{{ item.title }}</span>
						<span class="secondary">{{ item.title }}</span>
					</a>
					<router-link v-else :to="'/' + item.slug">
						<span class="primary">{{ item.title }}</span>
						<span class="secondary">{{ item.title }}</span>
					</router-link>
				</li>
			</ul>
			<div class="medium-hide">
				<span class="search-icon" @click="toggleSearch()">
					<span class="icon-search"></span>
				</span>
				<search v-show="searchState" />
			</div>
			<div class="header-menu" @click="openNavigation()">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	</header>
</template>

<script>
import { EventBus } from '@/app/event-bus'
import { mapState } from 'vuex'
import Search from '@/components/Search'

export default {
	name: 'HeaderItem',
	components: {
		Search
	},
	data () {
		return {
			dark: false,
			searchState: false,
			showHeader: false,
			scrollValue: 0
		}
	},
	computed: {
        ...mapState(['theme', 'general'])
	},
	created () {
		window.addEventListener('scroll', this.detectDirection)
	},
	mounted () {
		EventBus.$on('close-search', (payload) => this.searchState = payload)
	},
	unmounted () {
		EventBus.$off('close-search')
		window.removeEventListener('scroll', this.detectDirection)
	},
	methods: {
		detectDirection () {
			this.showHeader = this.scrollValue - window.pageYOffset > 0
			this.scrollValue = window.pageYOffset
		},
		toggleStyle () {
			let currentTheme = this.theme === 'light' ? 'dark' : 'light'
			localStorage.setItem('THEME', currentTheme)
			this.$store.dispatch('updateTheme', currentTheme)
		},
		openNavigation () {
			this.$store.dispatch('updateLock', true)
			this.$store.dispatch('updateNavigationActive', true)
		},
		toggleSearch () {
			if (!this.searchState) {
				EventBus.$emit('load-search-data')
			}
			this.searchState = !this.searchState
		}
	}
}
</script>
