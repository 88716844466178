<template>
	<div class="search">
		<input type="text" @input="onChange" v-model="search" ref="searchInput" />
		<span class="search-close" @click="closeSearch()">
			<span class="icon-close"></span>
		</span>
		<span class="search-submit">
			<span class="icon-search"></span>
		</span>
		<div v-show="isSearchOpen" class="search-results">
			<div v-if="results.length === 0" class="search-results-empty">
				Nothing found :(
			</div>
			<a v-else :href="'artists/' + result.slug" v-for="(result, index) in results" :key="index" @click.prevent="selectResult(result.slug)" class="search-results-element">
				<div class="search-results-element-content">
					<div class="search-results-element-content-image" :style="'background-image: url(' + replaceImagePath(result.main_image) + ')'"></div>
					<div class="search-results-element-content-title">
						{{ result.title }}
					</div>
					<div class="search-results-element-content-country">
						{{ result.country }}
					</div>
				</div>
			</a>
		</div>
	</div>
</template>

<script>
import { HTTP } from '@/app/api'
import { EventBus } from '@/app/event-bus'
import { mapState } from 'vuex'

export default {
	name: 'SearchItem',
	data () {
		return {
			isSearchOpen: false,
			search: '',
			results: [],
			isLoading: false
		};
	},
	computed: {
        ...mapState(['artists'])
	},
	mounted () {
		document.addEventListener('click', this.handleClickOutside)
		EventBus.$on('load-search-data', () => this.loadArtists())
	},
	unmounted () {
		EventBus.$off('load-search-data')
		document.removeEventListener('click', this.handleClickOutside)
	},
	methods: {
		onChange () {
			this.$emit('input', this.search)
			this.filterResults()
			this.isSearchOpen = true
		},
		filterResults () {
			this.results = this.artists.filter(item => {
				return item.title.toLowerCase().indexOf(this.search.toLowerCase()) > -1
			})
		},
		handleClickOutside () {
			this.isSearchOpen = false
		},
		closeSearch () {
			EventBus.$emit('close-search', false)
			this.search = ''
		},
		selectResult (slug) {
			this.isSearchOpen = false
			EventBus.$emit('close-search', false)
			this.$store.dispatch('updateLock', false)
			this.$store.dispatch('updateNavigationActive', false)
			this.search = ''
			this.$router.push('/artists/' + slug)
		},
		loadArtists () {
			if (this.artists.length === 0) {
				return HTTP ({
					method: 'GET',
					url: '/artists',
					crossdomain: true
				})
				.then(response => {
					this.$store.dispatch('updateArtists', response.data)
				})
			}
			setTimeout (() => {
				this.$refs.searchInput.focus()
			}, 100)
		}
	}
}
</script>
