export const loadOneTrust = () => {

    let dataDomainScript = process.env.VUE_APP_COUNTRY === 'nz' ? '7941b18e-e6dc-4c22-87a0-521e6191f6db' : 'ea3396b9-9e32-4ace-818f-14eddebc2058'
	let oneTrustScriptDOM = document.createElement('script')
	oneTrustScriptDOM.setAttribute('src', "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js")
	oneTrustScriptDOM.setAttribute('id', "onetrustcdn")
	oneTrustScriptDOM.setAttribute('data-document-language', "true")
	oneTrustScriptDOM.setAttribute('data-domain-script', dataDomainScript)
	oneTrustScriptDOM.setAttribute('charset', "UTF-8")
	oneTrustScriptDOM.setAttribute('type', 'text/javascript')
	document.head.prepend(oneTrustScriptDOM)

    window.OptanonWrapper = function() {
        var allLinks = document.querySelectorAll('a[href*="https://www.wminewmedia.com/cookies-policy/"]:not([href*="?ot="])');
        for(var i = 0; i < allLinks.length; i++) {
            let href = allLinks[i].href;
            href = href + '?ot=' + document.getElementById('onetrustcdn').getAttribute('data-domain-script') + '&url=' + window.location.hostname;
            allLinks[i].setAttribute("href", href);
            allLinks[i].setAttribute("target", "_blank");
        }

        //var eOT = new Event("OneTrustGroupsUpdated");
        //document.dispatchEvent(eOT);

    }

    window.getCookie = function(name) {
        var value = "; " + document.cookie;
        var parts = value.split("; " + name + "=");
        if (parts.length == 2) {
            return true;
        }
    }

    window.reloadOTBanner = function() {

        var otConsentSdk = document.getElementById("onetrust-consent-sdk");
        if (otConsentSdk) {
            otConsentSdk.remove();
        }

        if (window.OneTrust != null) {
            window.OneTrust.Init();

            setTimeout(function() {
                window.OneTrust.LoadBanner();

                var toggleDisplay = document.getElementsByClassName(
                    "ot-sdk-show-settings"
                );

                for (var i = 0; i < toggleDisplay.length; i++) {
                    toggleDisplay[i].onclick = function(event) {
                        event.stopImmediatePropagation();
                        window.OneTrust.ToggleInfoDisplay();
                    };
                }
            }, 1000);
        }
    }

    return

}

export const reloadOneTrust = () => {
    
    window.reloadOTBanner()
    console.log("reloaded oneTrust")

}