export default {
	methods: {
		checkObject (value) {
			return Object.entries(value).length
		},
		calculateFooterHeight () {
			document.getElementById('main').style.minHeight = window.innerHeight - document.getElementById('footer').clientHeight + 'px'
		},
		openLightbox (value, id = 0) {
			this.$store.dispatch('updateLightbox', {
				video: value,
				active: true,
				id
			})
			this.$store.dispatch('updateLock', true)
		},
		replaceImagePath (value) {
			if (value) {
				return value.replace(process.env.VUE_APP_API, process.env.VUE_APP_MEDIA)
			}
		}
	}
}
