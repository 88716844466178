import Vue from 'vue'
import App from '@/App'
import Meta from 'vue-meta'
import VueAxios from 'vue-axios'
import router from '@/app/routes'
import { HTTP } from '@/app/api'
import { store } from '@/app/store'
import SocialSharing from 'vue-social-sharing'
import Mixins from '@/app/mixins'
import ScrollAnimation from '@/app/directives/scrollAnimations'

import '@/style/style.scss'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAxios, HTTP)
Vue.use(Meta)

Vue.mixin(Mixins)
Vue.use(SocialSharing);
Vue.directive('scrollanimation', ScrollAnimation)
Vue.config.productionTip = false


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
