import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
	state: {
		loading: true,
		country: process.env.VUE_APP_COUNTRY,
		theme: 'light',
		lock: false,
		general: {},
		navigationActive: false,
		homePage: {},
		homeMerchandise: [],
		tours: [],
		genres: [],
		categories: [],
		quiz: {},
		quizPage: {},
		newsPage: {},
		artistsPage: {},
		artists: [],
		artistLoader: null,
		aboutPage: [],
		contactPage: [],
		winPage: [],
		wins: {
			ongoing: {
				collection: [],
				count: null
			},
			archived: {
				collection: [],
				count: null
			},
		},
		lightbox: {
			active: false,
			video: null
		},
	},
	mutations: {
		SET_LOCK(state, payload) {
			state.lock = payload
		},
		SET_THEME(state, payload) {
			state.theme = payload
		},
		SET_LOADING(state, payload) {
			state.loading = payload
		},
		SET_NAVIGATION_ACTIVE(state, payload) {
			state.navigationActive = payload
		},
		SET_GENERAL(state, payload) {
			state.general = payload
		},

		SET_HOME_PAGE(state, payload) {
			state.homePage = payload
		},
		SET_HOME_MERCHANDISE(state, payload) {
			state.homeMerchandise = payload
		},
		SET_QUIZ(state, payload) {
			state.quiz = payload
		},
		SET_NEWS_PAGE(state, payload) {
			state.newsPage = payload
		},
		SET_CATEGORIES(state, payload) {
			state.categories = payload
		},
		SET_ARTISTS(state, payload) {
			state.artists = payload
		},
		SET_ARTIST_LOADER(state, payload) {
			state.artistLoader = payload
		},
		SET_GENRES(state, payload) {
			state.genres = payload
		},
		SET_ABOUT_PAGE(state, payload) {
			state.aboutPage = payload
		},
		SET_ARTISTS_PAGE(state, payload) {
			state.artistsPage = payload
		},
		SET_QUIZ_PAGE(state, payload) {
			state.quizPage = payload
		},
		SET_CONTACT_PAGE(state, payload) {
			state.contactPage = payload
		},
		SET_WIN_PAGE(state, payload) {
			state.winPage = payload
		},
		SET_WIN_MORE(state, payload) {
			state.wins[payload.type].collection = state.wins[payload.type].collection.concat(payload.data)
		},
		SET_WIN(state, payload) {
			state.wins[payload.type] = payload.data
		},
		SET_LIGHTBOX_DATA(state, payload) {
			state.lightbox = payload
		},
		SET_TOURS(state, payload) {
			state.tours = payload
		},
	},
	actions: {
		updateLock (context, payload) {
			context.commit('SET_LOCK', payload)
			if (payload) {
				document.body.classList.add('lock')
			} else {
				document.body.classList.remove('lock')
			}
		},
		updateTheme (context, payload) {
			context.commit('SET_THEME', payload)
			document.body.classList.remove('light')
			document.body.classList.remove('dark')
			document.body.classList.add(payload)
		},
		updateLoading (context, payload) {
			context.commit('SET_LOADING', payload)
			if (payload) {
				document.body.classList.add('lock')
			} else {
				document.body.classList.remove('lock')
			}
		},
		updateNavigationActive (context, payload) {
			context.commit('SET_NAVIGATION_ACTIVE', payload)
		},
		updateGeneral (context, payload) {
			context.commit('SET_GENERAL', payload)
		},
		updateHomePage (context, payload) {
			context.commit('SET_HOME_PAGE', payload)
		},
		updateHomeMerchandise (context, payload) {
			context.commit('SET_HOME_MERCHANDISE', payload)
		},
		updateCategories (context, payload) {
			context.commit('SET_CATEGORIES', payload)
		},
		updateArtists (context, payload) {
			context.commit('SET_ARTISTS', payload)
		},
		updateArtistLoader (context, payload) {
			context.commit('SET_ARTIST_LOADER', payload)
		},
		updateQuiz (context, payload) {
			context.commit('SET_QUIZ', payload)
		},
		updateQuizPage (context, payload) {
			context.commit('SET_QUIZ_PAGE', payload)
		},
		updateNewsPage (context, payload) {
			context.commit('SET_NEWS_PAGE', payload)
		},
		updateGenres (context, payload) {
			context.commit('SET_GENRES', payload)
		},
		updateAboutPage (context, payload) {
			context.commit('SET_ABOUT_PAGE', payload)
		},
		updateArtistsPage (context, payload) {
			context.commit('SET_ARTISTS_PAGE', payload)
		},
		updateContactPage (context, payload) {
			context.commit('SET_CONTACT_PAGE', payload)
		},
		updateWinPage (context, payload) {
			context.commit('SET_WIN_PAGE', payload)
		},
		updateWin (context, payload) {
			context.commit('SET_WIN', payload)
		},
		updateWinMore (context, payload) {
			context.commit('SET_WIN_MORE', payload)
		},
		updateLightbox (context, payload) {
			context.commit('SET_LIGHTBOX_DATA', payload)
		},
		updateTours (context, payload) {
			context.commit('SET_TOURS', payload)
		},
	}
});
