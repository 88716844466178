<template>
	<div class="modal" :class="{ 'active': opened }">
		<div v-if="general.subscribe" class="modal-content" :class="{ 'active': opened }">
			<div @click="closeSubscribeModal()" class="modal-content-close">
				<span class="icon-close"></span>
			</div>
			<div v-if="sent" class="modal-content-title">
				{{ general.subscribe.thank_you_title }}
			</div>
			<div v-else class="modal-content-title">
				{{ general.subscribe.title }}
			</div>
			<div v-if="!sent" class="modal-content-subtitle">
				{{ general.subscribe.subtitle }}
			</div>
			<div v-if="sent" class="modal-content-success">
				{{ general.subscribe.thank_you_message }}
			</div>
			<form v-else class="modal-content-form">
				<div class="row">
					<div class="col-lg-12">
						<input v-model="subscribeData.zipCode" type="text" class="form-input" placeholder="Postal code" />
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<select v-model="subscribeData.country" class="form-select">
							<option disabled selected value="">Country</option>
							<option v-for="(item, index) in countries" :key="index" :value="item.key">{{ item.name }}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<input v-model="subscribeData.firstName" type="text" class="form-input" placeholder="First name" />
					</div>
				</div>
				<div class="row middle">
					<div class="col-lg-4">
						Date of birth
					</div>
					<div class="col-lg-4 col-xs-6">
						<select v-model="subscribeData.monthOfBirth" class="form-select">
							<option disabled selected value="">Month</option>
							<option v-for="(item, index) in months" :key="index" :value="parseInt(index + 1)">{{ item }}</option>
						</select>
					</div>
					<div class="col-lg-4 col-xs-6">
						<select v-model="subscribeData.dayOfBirth" class="form-select">
							<option disabled selected value="">choose ...</option>
							<option v-for="item in 31" :key="item" :value="item">{{ item }}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<label class="form-checkbox">
							<input v-model="subscribeData.accepts" type="checkbox" />
							<span>{{ general.subscribe.checkbox }}</span>
						</label>
						<span class="modal-content-form-terms">
							Click to view our
							<a href="https://www.wminewmedia.com/privacy/au" target="_blank">Privacy</a> &amp;
							<a href="/page/terms-of-use" target="_blank"> Terms &amp; Conditions</a>
						</span>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12">
						<div class="form-submit">
							<span class="text-uppercase">Send</span>
							<button @click.prevent="submitSubscribe()" type="submit" class="button-arrow var-1" :disabled="!isValidForm()">
								<span class="button-arrow-wrapper"></span>
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import { EventBus } from '@/app/event-bus'
import { mapState } from 'vuex'
import { trackAdobe } from '../../utils/adobe'

export default {
	name: 'SubscribePopup',
	data () {
		return {
			opened: false,
			sent: false,
			subscribeData: {
				email: '',
				zipCode: '',
				firstName: '',
				country: '',
				monthOfBirth: '',
				dayOfBirth: '',
				accepts: false
			},
			months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
			countries: [
				{ key: 'AF', name: "Afghanistan" },
				{ key: 'AL', name: "Albania" },
				{ key: 'DZ', name: "Algeria" },
				{ key: 'AS', name: "American Samoa" },
				{ key: 'AD', name: "Andorra" },
				{ key: 'AO', name: "Angola" },
				{ key: 'AI', name: "Anguilla" },
				{ key: 'AQ', name: "Antarctica" },
				{ key: 'AG', name: "Antigua and Barbuda" },
				{ key: 'AR', name: "Argentina" },
				{ key: 'AM', name: "Armenia" },
				{ key: 'AW', name: "Aruba" },
				{ key: 'AU', name: "Australia" },
				{ key: 'AT', name: "Austria" },
				{ key: 'AZ', name: "Azerbaijan" },
				{ key: 'BS', name: "Bahamas" },
				{ key: 'BH', name: "Bahrain" },
				{ key: 'BD', name: "Bangladesh" },
				{ key: 'BB', name: "Barbados" },
				{ key: 'BY', name: "Belarus" },
				{ key: 'BE', name: "Belgium" },
				{ key: 'BZ', name: "Belize" },
				{ key: 'BJ', name: "Benin" },
				{ key: 'BM', name: "Bermuda" },
				{ key: 'BT', name: "Bhutan" },
				{ key: 'BO', name: "Bolivia " },
				{ key: 'BQ', name: "Bonaire, Sint Eustatius and Saba " },
				{ key: 'BA', name: "Bosnia and Herzegovina" },
				{ key: 'BW', name: "Botswana" },
				{ key: 'BV', name: "Bouvet Island" },
				{ key: 'BR', name: "Brazil" },
				{ key: 'IO', name: "British Indian Ocean Territory " },
				{ key: 'BN', name: "Brunei Darussalam" },
				{ key: 'BG', name: "Bulgaria" },
				{ key: 'BF', name: "Burkina Faso" },
				{ key: 'BI', name: "Burundi" },
				{ key: 'KH', name: "Cambodia" },
				{ key: 'CM', name: "Cameroon" },
				{ key: 'CA', name: "Canada" },
				{ key: 'CV', name: "Cape Verde" },
				{ key: 'KY', name: "Cayman Islands" },
				{ key: 'CF', name: "Central African Republic" },
				{ key: 'TD', name: "Chad" },
				{ key: 'CL', name: "Chile" },
				{ key: 'CN', name: "China" },
				{ key: 'CX', name: "Christmas Island" },
				{ key: 'CC', name: "Cocos (Keeling) Islands" },
				{ key: 'CO', name: "Colombia" },
				{ key: 'KM', name: "Comoros" },
				{ key: 'CG', name: "Congo" },
				{ key: 'CD', name: "Congo, The Democratic Republic Of The " },
				{ key: 'CK', name: "Cook Islands" },
				{ key: 'CR', name: "Costa Rica" },
				{ key: 'HR', name: "Croatia" },
				{ key: 'CU', name: "Cuba" },
				{ key: 'CW', name: "Curaçao " },
				{ key: 'CY', name: "Cyprus" },
				{ key: 'CZ', name: "Czech Republic " },
				{ key: 'CI', name: "Côte D'Ivoire " },
				{ key: 'DK', name: "Denmark " },
				{ key: 'DJ', name: "Djibouti" },
				{ key: 'DM', name: "Dominica" },
				{ key: 'DO', name: "Dominican Republic" },
				{ key: 'EC', name: "Ecuador" },
				{ key: 'EG', name: "Egypt" },
				{ key: 'SV', name: "El Salvador" },
				{ key: 'GQ', name: "Equatorial Guinea" },
				{ key: 'ER', name: "Eritrea" },
				{ key: 'EE', name: "Estonia" },
				{ key: 'ET', name: "Ethiopia" },
				{ key: 'FK', name: "Falkland Islands (Malvinas)" },
				{ key: 'FO', name: "Faroe Islands" },
				{ key: 'FJ', name: "Fiji" },
				{ key: 'FI', name: "Finland" },
				{ key: 'FR', name: "France" },
				{ key: 'GF', name: "French Guiana" },
				{ key: 'PF', name: "French Polynesia" },
				{ key: 'TF', name: "French Southern Territories" },
				{ key: 'GA', name: "Gabon" },
				{ key: 'GM', name: "Gambia" },
				{ key: 'GE', name: "Georgia" },
				{ key: 'DE', name: "Germany" },
				{ key: 'GH', name: "Ghana" },
				{ key: 'GI', name: "Gibraltar" },
				{ key: 'GR', name: "Greece" },
				{ key: 'GL', name: "Greenland" },
				{ key: 'GD', name: "Grenada" },
				{ key: 'GP', name: "Guadeloupe" },
				{ key: 'GU', name: "Guam" },
				{ key: 'GT', name: "Guatemala" },
				{ key: 'GG', name: "Guernsey" },
				{ key: 'GN', name: "Guinea" },
				{ key: 'GW', name: "Guinea-Bissau" },
				{ key: 'GY', name: "Guyana" },
				{ key: 'HT', name: "Haiti" },
				{ key: 'HM', name: "Heard Island and McDonald Islands " },
				{ key: 'VA', name: "Holy See (Vatican City State) " },
				{ key: 'HN', name: "Honduras" },
				{ key: 'HK', name: "Hong Kong" },
				{ key: 'HU', name: "Hungary" },
				{ key: 'IS', name: "Iceland" },
				{ key: 'IN', name: "India" },
				{ key: 'ID', name: "Indonesia" },
				{ key: 'IR', name: "Iran, Islamic Republic Of" },
				{ key: 'IQ', name: "Iraq" },
				{ key: 'IE', name: "Ireland" },
				{ key: 'IM', name: "Isle of Man" },
				{ key: 'IL', name: "Israel" },
				{ key: 'IT', name: "Italy" },
				{ key: 'JM', name: "Jamaica" },
				{ key: 'JP', name: "Japan" },
				{ key: 'JE', name: "Jersey" },
				{ key: 'JO', name: "Jordan" },
				{ key: 'KZ', name: "Kazakhstan" },
				{ key: 'KE', name: "Kenya" },
				{ key: 'KI', name: "Kiribati" },
				{ key: 'KP', name: "Korea,  Democratic People's Republic Of " },
				{ key: 'KR', name: "Korea, Republic of" },
				{ key: 'KW', name: "Kuwait" },
				{ key: 'KG', name: "Kyrgyzstan" },
				{ key: 'LA', name: "Lao People's Democratic Republic" },
				{ key: 'LV', name: "Latvia" },
				{ key: 'LB', name: "Lebanon" },
				{ key: 'LS', name: "Lesotho" },
				{ key: 'LR', name: "Liberia" },
				{ key: 'LY', name: "Libya" },
				{ key: 'LI', name: "Liechtenstein" },
				{ key: 'LT', name: "Lithuania" },
				{ key: 'LU', name: "Luxembourg" },
				{ key: 'MO', name: "Macao" },
				{ key: 'MK', name: "Macedonia, the Former Yugoslav Republic Of" },
				{ key: 'MG', name: "Madagascar" },
				{ key: 'MW', name: "Malawi" },
				{ key: 'MY', name: "Malaysia" },
				{ key: 'MV', name: "Maldives" },
				{ key: 'ML', name: "Mali" },
				{ key: 'MT', name: "Malta" },
				{ key: 'MH', name: "Marshall Islands" },
				{ key: 'MQ', name: "Martinique" },
				{ key: 'MR', name: "Mauritania" },
				{ key: 'MU', name: "Mauritius" },
				{ key: 'YT', name: "Mayotte" },
				{ key: 'MX', name: "Mexico" },
				{ key: 'FM', name: "Micronesia, Federated States Of" },
				{ key: 'MD', name: "Moldova" },
				{ key: 'MC', name: "Monaco" },
				{ key: 'MN', name: "Mongolia" },
				{ key: 'ME', name: "Montenegro" },
				{ key: 'MS', name: "Montserrat" },
				{ key: 'MA', name: "Morocco" },
				{ key: 'MZ', name: "Mozambique" },
				{ key: 'MM', name: "Myanmar" },
				{ key: 'NA', name: "Namibia" },
				{ key: 'NR', name: "Nauru" },
				{ key: 'NP', name: "Nepal" },
				{ key: 'NL', name: "Netherlands" },
				{ key: 'NC', name: "New Caledonia" },
				{ key: 'NZ', name: "New Zealand" },
				{ key: 'NI', name: "Nicaragua" },
				{ key: 'NE', name: "Niger" },
				{ key: 'NG', name: "Nigeria" },
				{ key: 'NU', name: "Niue" },
				{ key: 'NF', name: "Norfolk Island" },
				{ key: 'MP', name: "Northern Mariana Islands" },
				{ key: 'NO', name: "Norway" },
				{ key: 'OM', name: "Oman" },
				{ key: 'PK', name: "Pakistan" },
				{ key: 'PW', name: "Palau" },
				{ key: 'PA', name: "Panama" },
				{ key: 'PG', name: "Papua New Guinea " },
				{ key: 'PY', name: "Paraguay" },
				{ key: 'PE', name: "Peru" },
				{ key: 'PH', name: "Philippines" },
				{ key: 'PN', name: "Pitcairn" },
				{ key: 'PL', name: "Poland" },
				{ key: 'PT', name: "Portugal" },
				{ key: 'PR', name: "Puerto Rico " },
				{ key: 'QA', name: "Qatar" },
				{ key: 'RO', name: "Romania" },
				{ key: 'RU', name: "Russian Federation " },
				{ key: 'RW', name: "Rwanda" },
				{ key: 'RE', name: "Réunion" },
				{ key: 'BL', name: "Saint Barthélemy" },
				{ key: 'SH', name: "Saint Helena, Ascension and Tristan Da Cunha " },
				{ key: 'KN', name: "Saint Kitts And Nevis" },
				{ key: 'LC', name: "Saint Lucia" },
				{ key: 'MF', name: "Saint Martin (French Part)" },
				{ key: 'PM', name: "Saint Pierre And Miquelon" },
				{ key: 'VC', name: "Saint Vincent And The Grenadines" },
				{ key: 'WS', name: "Samoa" },
				{ key: 'SM', name: "San Marino" },
				{ key: 'ST', name: "Sao Tome and Principe" },
				{ key: 'SA', name: "Saudi Arabia" },
				{ key: 'SN', name: "Senegal" },
				{ key: 'RS', name: "Serbia" },
				{ key: 'SC', name: "Seychelles" },
				{ key: 'SL', name: "Sierra Leone" },
				{ key: 'SG', name: "Singapore" },
				{ key: 'SX', name: "Sint Maarten (Dutch part)" },
				{ key: 'SK', name: "Slovakia" },
				{ key: 'SI', name: "Slovenia" },
				{ key: 'SB', name: "Solomon Islands" },
				{ key: 'SO', name: "Somalia" },
				{ key: 'ZA', name: "South Africa" },
				{ key: 'GS', name: "South Georgia and the South Sandwich Islands " },
				{ key: 'SS', name: "South Sudan" },
				{ key: 'ES', name: "Spain" },
				{ key: 'LK', name: "Sri Lanka" },
				{ key: 'SD', name: "Sudan" },
				{ key: 'SR', name: "Suriname" },
				{ key: 'SJ', name: "Svalbard And Jan Mayen" },
				{ key: 'SZ', name: "Swaziland" },
				{ key: 'SE', name: "Sweden" },
				{ key: 'CH', name: "Switzerland" },
				{ key: 'SY', name: "Syrian Arab Republic" },
				{ key: 'TW', name: "Taiwan,  Province Of China" },
				{ key: 'TJ', name: "Tajikistan" },
				{ key: 'TZ', name: "Tanzania,  United Republic of" },
				{ key: 'TH', name: "Thailand" },
				{ key: 'TL', name: "Timor-Leste" },
				{ key: 'TG', name: "Togo" },
				{ key: 'TK', name: "Tokelau" },
				{ key: 'TO', name: "Tonga" },
				{ key: 'TT', name: "Trinidad and Tobago " },
				{ key: 'TN', name: "Tunisia" },
				{ key: 'TR', name: "Turkey" },
				{ key: 'TM', name: "Turkmenistan" },
				{ key: 'TC', name: "Turks and Caicos Islands" },
				{ key: 'TV', name: "Tuvalu" },
				{ key: 'UG', name: "Uganda" },
				{ key: 'UA', name: "Ukraine" },
				{ key: 'AE', name: "United Arab Emirates" },
				{ key: 'GB', name: "United Kingdom" },
				{ key: 'US', name: "United States" },
				{ key: 'UM', name: "United States Minor Outlying Islands " },
				{ key: 'UY', name: "Uruguay" },
				{ key: 'UZ', name: "Uzbekistan" },
				{ key: 'VU', name: "Vanuatu" },
				{ key: 'VE', name: "Venezuela,  Bolivarian Republic of " },
				{ key: 'VN', name: "Viet Nam" },
				{ key: 'VG', name: "Virgin Islands,  British" },
				{ key: 'VI', name: "Virgin Islands, U.S." },
				{ key: 'WF', name: "Wallis and Futuna" },
				{ key: 'EH', name: "Western Sahara" },
				{ key: 'YE', name: "Yemen" },
				{ key: 'ZM', name: "Zambia" },
				{ key: 'ZW', name: "Zimbabwe" },
				{ key: 'AX', name: "Åland Islands" },
			]
		}
	},
	computed: {
        ...mapState(['general'])
	},
	mounted () {
		EventBus.$on('open-subscribe-popup', (payload) => {
			this.opened = payload.opened
			this.subscribeData.email = payload.email
		})
	},
	unmounted () {
		EventBus.$off('open-subscribe-popup')
	},
	methods: {
		closeSubscribeModal () {
			this.opened = false
			setTimeout (() => {
				this.sent = false
				this.$store.dispatch('updateLock', false)
			}, 300)
		},
		submitSubscribe () {
			if (this.isValidForm()) {
				let output = {
					NewsletterID: process.env.VUE_APP_NEWSLETTER_ID,
					ref_url: process.env.VUE_APP_DOMAIN,
					datasource: process.env.VUE_APP_NEWSLETTER_SOURCE,
					firstname: this.subscribeData.firstName,
					email: this.subscribeData.email,
					postalcode: this.subscribeData.zipCode,
					country: this.subscribeData.country,
					dobMonth: this.subscribeData.monthOfBirth,
					dobDay: this.subscribeData.dayOfBirth,
				}
				fetch('https://signup.wmg.com/register', {
					method: 'POST',
					body: JSON.stringify(output),
				})
				.then(() => {
					this.sent = true
					trackAdobe('email sign-up')
				})
			}
		},
		isValidForm () {
			return (
				this.subscribeData.email != ''
				&& this.subscribeData.firstName != ''
				&& this.subscribeData.accepts
			)
		}
	}
}
</script>
