<template>
	<div class="artists-loader" :class="{ 'active': artistLoader !== null }" :style="'background-image: url(' + artistLoader + ')'"></div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'ArtistLoader',
	computed: {
        ...mapState(['artistLoader'])
	}
}
</script>
