import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home')
const About = () => import(/* webpackChunkName: "About" */ '@/views/About')
const Artists = () => import(/* webpackChunkName: "Artists" */ '@/views/Artists/ArtistsPage')
const ArtistsArticle = () => import(/* webpackChunkName: "ArtistsArticle" */ '@/views/Artists/ArtistsArticle')
const News = () => import(/* webpackChunkName: "News" */ '@/views/News/NewsPage')
const NewsArticle = () => import(/* webpackChunkName: "NewsArticle" */ '@/views/News/NewsArticle')
const Contact = () => import(/* webpackChunkName: "Contact" */ '@/views/Contact')
const Win = () => import(/* webpackChunkName: "Win" */ '@/views/Win')
const Page = () => import(/* webpackChunkName: "Page" */ '@/views/Page')
const Error = () => import(/* webpackChunkName: "404" */ '@/views/404')



let router = new Router({
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/index.html', // needed for crawlers
			redirect: { name: 'home' }
		},
		{
			path: '/about',
			component: About
		},
		{
			path: '/artists',
			name: 'artists',
			component: Artists
		},
		{
			path: '/artists/:slug',
			name: 'artist',
			component: ArtistsArticle
		},

		{
			path: '/contact',
			component: Contact
		},
		{
			path: '/news',
			name: 'news',
			component: News
		},
		{
			path: '/news/category/:category_slug',
			name: 'newsCategory',
			component: News
		},
		{
			path: '/news/category/',
			redirect: { name: 'news' }
		},
		{
			path: '/news/tag/',
			redirect: { name: 'news' }
		},
		{
			path: '/news/:slug',
			name: 'newsArticle',
			component: NewsArticle
		},
		{
			path: '/news/tag/:tag_slug',
			name: 'newsTag',
			component: News
		},
		{
			path: '/win',
			component: Win
		},
		{
			path: '/404',
			component: Error
		},
		{
			path: '/page/:page_slug',
			component: Page
		},
		{
			path: '*',
			component: Error
		}
	]
})

export default router
