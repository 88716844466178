/*
    Set digitalData on window and add adobe to window of not exists. If adobe exists, trigger pageview event
*/
export const loadAdobe = (view, data = null) => {

    let reportsuites, sitename
    if (process.env.VUE_APP_COUNTRY === 'nz') {
        reportsuites = 'wmg';
        sitename = 'Warner Music New Zealand';
    } else {
        reportsuites = 'wmg,wmgau';
        sitename = 'Warner Music Australia';
    }

    let page = {}

    switch (view) {
        case 'home':
            page = {
                pageInfo: {
                    pageName: sitename + ":Homepage",
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":Home",
                    pageType: "homepage"
                }
            }

            break
        case 'artist':
            page = {
                pageInfo: {
                    pageName: sitename + ":Artists:" + data,
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":Artists",
                    pageType: "artists:detail"
                }
            }

            break
        case 'artists':
            page = {
                pageInfo: {
                    pageName: sitename + ":Artists",
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":Artists",
                    pageType: "artists:landing"
                }
            }

            break
        case 'news_landing':
            page = {
                pageInfo: {
                    pageName: sitename + ":News",
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":News",
                    pageType: "news:landing"
                }
            }

            break
        case 'news_category':
            page = {
                pageInfo: {
                    pageName: sitename + ":News:" + data,
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":News",
                    pageType: "news:category"
                }
            }
            break
        case 'win':
            page = {
                pageInfo: {
                    pageName: sitename + ":News:Win",
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":News",
                    pageType: "news:category"
                }
            }
            break
        case 'news_article':
            page = {
                pageInfo: {
                    pageName: sitename + ":News:" + data,
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":News",
                    pageType: "news:article"
                }
            }

            break
        case 'about':
            page = {
                pageInfo: {
                    pageName: sitename + ":About",
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":About",
                    pageType: "about"
                }
            }
            break
        case 'contact':
            page = {
                pageInfo: {
                    pageName: sitename + ":Contact Us",
                    server: sitename + ":Site",
                    platform: "WordPress",
                    devTeam: sitename
                },
                category: {
                    primaryCategory: sitename + ":Contact Us",
                    pageType: "contact us"
                }
            }
            break
        case 'default':
            console.error('Invalid page')
            page = {}
            break
    }

    const digitalData = {
        settings: {
            reportSuites: reportsuites
        },
        content: {
            artist: sitename,
            label: "Warner Music International",
            sublabel: sitename
        },
        page
    }

    window.digitalData = digitalData

    if (!window.adobeLaunchLoaded) {

        // is initial load -- page view will fire after load if digitaldata is present
        addAdobeScript()

    } else {

        // script exists -- fire event manually
        document.dispatchEvent(new CustomEvent('SPAPageTriggered'))

    }

}

/*
    Add adobe launch script to DOM and set option to loaded
*/
const addAdobeScript = () => {

    const adobeScript = '//assets.adobedtm.com/launch-EN302b8a31b75a4dda8ff8df1d0cdb4762.min.js'
    let adobeScriptDOM = document.createElement('script')
    adobeScriptDOM.setAttribute('src', adobeScript)
    adobeScriptDOM.setAttribute('async', '')
    adobeScriptDOM.setAttribute('type', 'text/javascript')
    document.head.appendChild(adobeScriptDOM)

    window.adobeLaunchLoaded = true

    return

}

/*
    Trigger custom named events that don't involve the digital data object
*/
export const trackAdobe = (event) => {

    window._satellite.track(event)
    return

}