<template>
	<div class="loading">
		<div class="header">
			<div class="header-logo" :class="$store.state.country"></div>
			<span class="switcher mobile-hide disabled">
				<span class="switcher-icon"></span>
			</span>
			<div class="header-navigation">
				<div class="medium-hide">
					<span class="search-icon disabled"></span>
				</div>
				<div class="header-menu disabled">
					<span></span>
					<span></span>
					<span></span>
				</div>
			</div>
		</div>
		<div class="loading-animation">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'LoadingItem',
}
</script>
