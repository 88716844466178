<template>
	<nav class="navigation" :class="{ 'active': navigationActive }" v-if="checkObject(general)">
		<div class="navigation-head">
			<router-link to="/" class="navigation-head-logo" :class="$store.state.country" @click="closeNavigation()"></router-link>
			<span class="switcher" @click="toggleStyle()">
				<span class="switcher-icon"></span>
				<span v-if="theme === 'light'" class="switcher-label">Dark mode</span>
				<span v-else class="switcher-label">Light mode</span>
			</span>
			<div class="navigation-head-close" @click="closeNavigation()">
				<span class="icon-close"></span>
			</div>
		</div>
		<div class="navigation-search">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div @click="loadSearch()">
							<search />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="navigation-body">
			<div class="container">
				<div class="row center">
					<div class="col-lg-12">
						<ul class="navigation-body-menu">
							<li @click="closeNavigation()" class="navigation-body-menu-item" v-for="(item, index) in general.main_navigation" :key="index">
								<a v-if="item.slug.includes('http')" :href="item.slug" target="_blank">
									<span class="navigation-body-menu-item-lead">
										0{{ index + 1 }}
									</span>
									{{ item.title }}
								</a>
								<router-link v-else :to="'/' + item.slug">
									<span class="navigation-body-menu-item-lead">
										0{{ index + 1 }}
									</span>
									{{item.title}}
								</router-link>
								<p class="navigation-body-menu-item-text" v-html="item.description"></p>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="navigation-bottom">
			<div class="container">
				<div class="row center">
					<div class="col-lg-12">
						<div class="mobile-show tablet-show">
							<div class="navigation-bottom-social">
								<a v-for="(item, index) in general.social_networks"
									:key="index"
									:href="item.url"
									target="_blank">
									<span :class="'icon-' + item.network.toLowerCase()"></span>
								</a>
							</div>
						</div>
						<ul class="navigation-bottom-menu">
							<li v-for="(item, index) in general.secondary_navigation" :key="index">
                                <a v-if="item.slug.includes('privacy')" :href="url" target="_blank" @click="closeNavigation()">
                                    {{ item.title }}
                                </a>
								<router-link v-else :to="'/' + item.slug" @click="closeNavigation()">
									{{ item.title }}
								</router-link>
							</li>
							<li class="copyright">
								{{ general.copyright_text }}
							</li>
						</ul>
						<div class="mobile-hide tablet-hide">
							<div class="navigation-bottom-social">
								<a v-for="(item, index) in general.social_networks"
									:key="index"
									:href="item.url"
									target="_blank">
									<span :class="'icon-' + item.network.toLowerCase()"></span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
import { EventBus } from '@/app/event-bus'
import { mapState } from 'vuex'
import Search from '@/components/Search'

export default {
	name: 'NavigationItem',
	components: {
		Search
	},
	data () {
		return {
			searchState: false,
            url: 'https://www.wminewmedia.com/privacy/au/'
		}
	},
	computed: {
        ...mapState(['theme', 'general', 'navigationActive'])
	},
	methods: {
		toggleStyle () {
			let currentTheme = this.theme === 'light' ? 'dark' : 'light'
			this.$store.dispatch('updateTheme', currentTheme)
		},
		closeNavigation () {
			this.$store.dispatch('updateLock', false)
			this.$store.dispatch('updateNavigationActive', false)
		},
		loadSearch () {
			EventBus.$emit('load-search-data')
		}
	}
}
</script>
