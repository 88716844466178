<template>
	<div class="lightbox" :class="{ 'active': lightbox.active }">
		<div @click="close()" class="lightbox-close" :class="{ 'active': lightbox.active }">
			<span class="icon-close"></span>
		</div>
		<div class="lightbox-bg" :class="{ 'active': lightbox.active }"></div>
		<div class="lightbox-content" :class="{ 'active': lightbox.active }">
			<div class="lightbox-content-modal">
				<div class="lightbox-content-modal-video">
					<iframe :src="'https://www.youtube-nocookie.com/embed/' + lightbox.video + '?autoplay=1&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0?enablejsapi=1'" frameborder="0" :id="'video' + lightbox.id"></iframe>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'LightboxItem',
	computed: {
		...mapState(['lightbox'])
	},
	methods: {
		close () {
			this.$store.dispatch('updateLightbox', {
				active: false,
				video: null
			})
			this.$store.dispatch('updateLock', false)
		}
	}
}
</script>
